import React, { useState, useEffect } from 'react';
import Dag from './Dag';
import './App.css';

function App() {
  const [winners, setWinners] = useState([]);

  /*
  const dinners = [
    {day:1,winner:1},{day:2,winner:["00000", "87656"]},{day:3,winner:0},{day:4,winner:0},{day:5,winner:0},{day:6,winner:0},
    {day:7,winner:0},{day:8,winner:0},{day:9,winner:0},{day:10,winner:0},{day:11,winner:0},{day:12,winner:0},
    {day:13,winner:0},{day:14,winner:0},{day:15,winner:0},{day:16,winner:0},{day:17,winner:0},{day:18,winner:0},
    {day:19,winner:0},{day:20,winner:0},{day:21,winner:0},{day:22,winner:0},{day:23,winner:"0"},{day:24,winner:["11111","88888"]},
 ]
    */

  const getWinner = (d) => {
    const w = (winners || []).find(winner => winner.day === d)
    return w ? w.winner : undefined
  }
  useEffect(() => {
      try {
       fetch('/api/Winners')
       .then(result => result.json()).catch((err) => console.error('2',err))
       .then(body => setWinners(body));
      } catch(err) {
        console.error(err);
      }
  }, []);

  return (
    <div>
      <div className='calendar-grid'>
        <div className="title">
          <h1><span className="title-1">Sæbø</span><span className="title-2">Skulemusikklag</span><span className="title-3">Adventskalendar</span></h1>
        </div>
        <Dag dato={1} premie="&#9829; 2 bill. Bygdekino + 50 egg" vinner={getWinner(1)} />
        <Dag dato={2} premie={['2 bill. pepperkakebyen','Gåvekort 300kr']} vinner={getWinner(2)} />
        <Dag dato={3} premie="Gåvekort klipp" vinner={getWinner(3)} />
        <Dag dato={4} premie="2 konsertbilletter" vinner={getWinner(4)} />
        <Dag dato={5} premie="&#9829; 3l Baronesse maling" vinner={getWinner(5)} />
        <Dag dato={6} premie="Gåvekort kake" vinner={getWinner(6)} />
        <Dag dato={7} premie="Fuglematar og solsikkefrø" vinner={getWinner(7)} />
        <Dag dato={8} premie={['Gåvekort pizza','OralB el. tannbørste']} vinner={getWinner(8)} />
        <Dag dato={9} premie="Gåvekort sushi 400kr" vinner={getWinner(9)} />
        <Dag dato={10} premie="&#9829; Klippekort Radøyhallen" vinner={getWinner(10)} />
        <Dag dato={11} premie="Hudpleieprodukter" vinner={getWinner(11)} />
        <Dag dato={12} premie={['Gåvekort Flipzone','2 bill EBML nyttårskonsert']} vinner={getWinner(12)} />
        <Dag dato={13} premie={['Gåvekort 250kr','Kos for kalde kveldar']} vinner={getWinner(13)} />
        <Dag dato={14} premie="&#9829; Gåvekort bowling" vinner={getWinner(14)} />
        <Dag dato={15} premie={['Gåvekort badstue 300kr','Gåvekort sushi 400kr']} vinner={getWinner(15)} />
        <Dag dato={16} premie="Dekkskift 500kr" vinner={getWinner(16)} />
        <Dag dato={17} premie="Matkorg" vinner={getWinner(17)} />
        <Dag dato={18} premie="Gåvekort 1t shuffleboard/biljard" vinner={getWinner(18)} />
        <Dag dato={19} premie="Toalettrullholdar + 50 egg" vinner={getWinner(19)} />
        <Dag dato={20} premie="&#9829; Dagskort Myrkdalen" vinner={getWinner(20)} />
        <Dag dato={21} premie={['Dørkrans','Oppladbar arbeidslampe']} vinner={getWinner(21)} />
        <Dag dato={22} premie={['Julematkasse','Køyretime 860kr']} vinner={getWinner(22)} />
        <Dag dato={23} premie={['Velværeprodukter','Krabbeteine']} vinner={getWinner(23)} />
        <Dag dato={24} premie={['Grunnkurs havkajakk 2p','Apple Airpods 4gen']} vinner={getWinner(24)} />
      </div>
    </div>
  );
}

export default App;
