import React from 'react';
import './index.css';

function Dag({dato, vinner, premie}) {
  return (
    <div className={"day day-" + dato}>
      <label>
        { vinner ? <input type="checkbox" /> : <></>}
        <div className='door'>
          <div className='front'>{dato}</div>
          <div className='back'></div>
        </div>
        <div className='inside'>
          <div className='vinner-container'>
            <span className={dato === 24 ? 'big-vinner-tall' : 'vinner-tall'}>
            {
              Array.isArray(vinner) 
              ? <div className='desc'>{'a.'+vinner[0]}<br/>{'b.'+vinner[1]}</div>
              : <div className='desc'>{vinner}</div>}
            </span>
          </div>
        </div>
        <div className="title-container">
          {
            Array.isArray(premie) 
            ? <div className='desc'>{'a.'+premie[0]}<br/>{'b.'+premie[1]}</div> 
            : <div className='desc'>{premie}</div>}
        </div>
      </label>
    </div>
  );
}

export default Dag;
